// Import package components
import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { Image, Flex, Select, SelectOption } from "uikit-react";
import moment from "moment";
import { useCookies } from "react-cookie";
import { Link } from "../../ui";

import { AppContexts } from "../../providers";

// Import common components
import RecordsPerPage from "../common/RecordsPerPage";
import Pagination from "../common/Pagination";
import AddTextLine from "../common/AddTextLine";

// Import message-templates components
import classnames from "classnames";
import TableLoading from "../common/Loading/table_loading";

const Notes = () => {
  const [criteria, setCriteria] = useState({
    per: 20,
    page: 0,
  });

  const { getAll, list: notes, loading } = useContext(AppContexts.NotesContext);

  useEffect(() => {
    document.title = "Notes - RueBaRue";
  }, []);

  useEffect(() => {
    getAll(criteria)
  }, [criteria]);

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  });

  const getGuestContactName = (note) => {
    let relatedGuestOrContact = note.guest.id 
      ? {}
      : note.guest_reservation.id 
        ? note.guest_reservation 
        : note.contact.id 
          ? note.contact 
          : {}
    return `${relatedGuestOrContact?.first_name || ""} ${relatedGuestOrContact?.last_name || ""}`
  }

  const noteRenderer = useCallback(
    (note) => (
      <tr key={note.id}>
        <td>
          {moment.utc(note.created_at).local().format('MM/DD/YYYY hh:mm A')}
        </td>
        <td>
          {note.first_name} {note.last_name}
        </td>
        <td>
          {getGuestContactName(note)}
        </td>
        <td className="message">
          <textarea rows="1" disabled="">
            {note.event_type ? note.event_type+" - "+moment.utc(note.event_date).local().format('MMM DD, YYYY') : note.note}
          </textarea>
        </td>
      </tr>
    ),
    [],
  );

  return (
    <>
      <section id="schedule-a-message">
        <AddTextLine feature="Notes" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Notes</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              {/*
              <Flex alignment="between top" className="guest-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default uk-form-custom uk-margin-small-right">
                        {criteria.search_name && criteria.search_name !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() =>
                              handleCriteriaChange("search_name", "")
                            }
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name"
                          value={criteria.search_name}
                          onChange={(event) =>
                            handleCriteriaChange(
                              "search_name",
                              event.target.value,
                            )
                          }
                        />
                      </div>
                      <div uk-form-custom="target: > * > span:first-child">
                        <Select
                          value={
                            criteria.search_folder === null
                              ? "All Folders"
                              : criteria.search_folder
                          }
                          onChange={(e) =>
                            handleCriteriaChange(
                              "search_folder",
                              e.target.value === "All" ? null : e.target.value,
                            )
                          }
                        >
                          <SelectOption value="">All</SelectOption>
                          {folderMap.map((folder, index) => (
                            <SelectOption value={folder} key={index}>
                              {folder}
                            </SelectOption>
                          ))}
                        </Select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="hms-sortby-btn">
                    <button className="uk-button" type="button">
                      SORT BY<span uk-icon="icon: triangle-down"></span>
                    </button>
                    <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "updated_at",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              handleCriteriaChange("sort", "updated_at")
                            }
                          >
                            Updated At
                          </Link>
                        </li>
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "name",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() => handleCriteriaChange("sort", "name")}
                          >
                            Name
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Flex>
              */}
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-expand">Added At</th>
                    <th className="uk-table-expand">Added By</th>
                    <th className="uk-table-expand">Guest/Contact</th>
                    <th className="message">Note/Event</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    loading 
                      ? TableLoading(4)
                      : notes?.paginated?.length > 0 
                        ? (
                          notes?.paginated?.map(noteRenderer)
                        ) : (
                          <tr>
                            <td colSpan={4} style={{ padding: "30px 25px" }}>
                              No notes matching your current search or filters.
                            </td>
                          </tr>
                        )
                  }
                </tbody>
              </table>
            </div>
          </div>

          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={notes.total}
              index={criteria.page}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
    </>
  );
};

export default Notes;
