import NoteAddContent from "./note-add-content";

const NoteAdd = ({ itemId, itemType }) => {
    return (
        <div
            id="modal-guest-note"
            className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
            uk-offcanvas="flip: true; overlay: true"
        >
            <div className="uk-offcanvas-bar">
                <div className="hms-modals-main">
                    <button
                        className="uk-offcanvas-close uk-close-large"
                        type="button"
                        data-uk-close
                    ></button>
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">
                            Notes
                        </h2>
                    </div>
                    <NoteAddContent itemId={itemId} itemType={itemType} />
                </div>
            </div>
        </div>
    )
}

export default NoteAdd;