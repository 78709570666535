// Import package components
import { createContext, useState } from "react";

import ChatbotService from "../services/chatbot.service";

export const ChatbotContext = createContext();
export const ChatbotProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [currentCompose, setCurrentCompose] = useState({});

  const contextChatbot = {
    loading,
    currentCompose,
    setLoading,
    setCurrentCompose,

    compose: async (account_id, user_id, question) => {
      setLoading(true);
      try {
        let res = await ChatbotService.composeLog(account_id, user_id, question);
        setLoading(false);
        console.log(res);
        setCurrentCompose(res?.data);
        return res.data;
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
  };

  return (
    <ChatbotContext.Provider value={contextChatbot}>
      {children}
    </ChatbotContext.Provider>
  );
};
