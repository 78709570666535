import { httpV2 } from "../http.js";

const InboxService = {
  get: () => httpV2.get(`inbox`),
  set: (inbox) => httpV2.post(`inbox`, inbox),
  getInboxes: (page, filter = "", pageSize = 20) =>
    httpV2.get(`inbox/search?page=${page}&filter=${filter}&pageSize=${pageSize}`),
  getInboxThread: (threadId) => httpV2.get(`inbox/thread/${threadId}`),
  getThreadOverview: (threadId) =>
    httpV2.get(`inbox/thread/${threadId}/overview`),
  setMeta: (threadId, data) =>
    httpV2.post(`inbox/thread/${threadId}/meta`, data),

  createRecipient: (recipient) => httpV2.post(`teams`, recipient),
  updateRecipient: (recipient) =>
    httpV2.put(`teams/${recipient.recipient_id}`, recipient),
  deleteRecipient: (recipient_id) => httpV2.delete(`teams/${recipient_id}`),
  inviteRecipient: (recipient_id) =>
    httpV2.post(`teams/${recipient_id}/invite`),
  rescindRecipient: (recipient_id) =>
    httpV2.post(`teams/${recipient_id}/rescind`),
};

export default InboxService;
