import { useState, useMemo, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import classnames from "classnames";
// Import base components
import { Link } from "../../../ui";

const Pagination = ({
  index = 0,
  count = 0,
  perPage = 0,
  onIndexChanged = () => {},
}) => {
  const [cookies] = useCookies(["records-per-page"]);
  const [pageIndex, setPageIndex] = useState(0);
  const [description, setDescription] = useState("");
  const [pageRanges, setPageRanges] = useState([]);
  const { "records-per-page": recordsPerPage } = cookies;

  const recordsPage = perPage || recordsPerPage;

  const pageCount = useMemo(
    () => Math.max(0, Math.ceil(count / (recordsPage ?? 10))),
    [count, recordsPage],
  );

  useEffect(() => setPageIndex(index), [index]);
  useEffect(() => {
    if (pageIndex >= 0 && pageIndex < pageCount) {
      onIndexChanged(pageIndex);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (pageCount === 0) {
      return;
    } else if (pageIndex < 0) {
      setPageIndex(0);
    } else if (pageIndex && pageIndex >= pageCount) {
      setPageIndex(pageCount - 1);
    }
  }, [pageIndex, pageCount]);

  useEffect(() => {
    console.log(pageIndex, count, pageRanges);
    setDescription(
      count == 0
        ? "No records found"
        : `${pageRanges[pageIndex]?.start + 1} - ${
            pageRanges[pageIndex]?.end
          } of ${count}`,
    );
  }, [pageIndex, count, JSON.stringify(pageRanges)]);

  useEffect(() => {
    const newPageRanges = [];
    for (let idx = 0; idx < pageCount; idx++) {
      const start = idx * recordsPage;
      const endInCalc = (idx + 1) * recordsPage;
      const end = endInCalc < count ? endInCalc : count;
      newPageRanges.push({ start, end });
    }
    setPageRanges(newPageRanges);
  }, [count, recordsPage, pageCount]);

  const renderPageIndexList = useCallback(
    () =>
      [...Array(pageCount).keys()].map((idx) => {
        const showIdx = idx + 1;
        if (pageCount > pageIndex + 3) {
          if (idx >= pageIndex && idx < pageIndex + 3) {
            return (
              <li
                className={classnames({ "uk-active": idx === pageIndex })}
                key={idx}
              >
                <Link href="#" onClick={() => setPageIndex(idx)}>
                  {showIdx}
                </Link>
              </li>
            );
          }
        } else {
          if (idx >= pageCount - 3 && idx < pageCount) {
            return (
              <li
                className={classnames({ "uk-active": idx === pageIndex })}
                key={idx}
              >
                <Link href="#" onClick={() => setPageIndex(idx)}>
                  {showIdx}
                </Link>
              </li>
            );
          }
        }
      }),
    [pageCount, pageIndex],
  );

  const handlePrevPage = useCallback(
    () => setPageIndex(pageIndex - 3),
    [pageIndex],
  );
  const handleNextPage = useCallback(
    () => setPageIndex(pageIndex + 3),
    [pageIndex],
  );

  return (
    <div className="hms-pagination uk-flex uk-flex-middle">
      <div className="hms-total-pagination">{description}</div>
      <ul className="uk-pagination uk-margin-remove" uk-margin="true">
        {count ? (
          <li className="hms-pagination-prev">
            <Link href="#" onClick={handlePrevPage}>
              <strong>{`<`}</strong>
            </Link>
          </li>
        ) : null}
        {renderPageIndexList()}
        {count ? (
          <li className="hms-pagination-next">
            <Link href="#" onClick={handleNextPage}>
              <strong>{`>`}</strong>
            </Link>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default Pagination;
