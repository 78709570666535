import axios from "axios";

let apiHost = ``;
if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "local") {
  apiHost = `http://localhost:4000/api`;
} else if (process.env.REACT_APP_ENV === "dev") {
  apiHost = `https://staging.api.ruebarue.com/dev/api`;
} else if (process.env.REACT_APP_ENV === "staging") {
  apiHost = `https://4t7cwt8tyk.execute-api.us-east-2.amazonaws.com/staging/api`;
} else if (process.env.REACT_APP_ENV === "prod") {
  apiHost = `https://api.staging.ruebarue.com/beta/api`;
}

let apiHostForChatbot = ``;
if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "local") {
  apiHostForChatbot = `http://localhost:4001/api`;
} else if (process.env.REACT_APP_ENV === "dev") {
  // apiHostForChatbot = `https://4t7cwt8tyk.execute-api.us-east-2.amazonaws.com/dev/api`;
} else if (process.env.REACT_APP_ENV === "staging") {
  // apiHostForChatbot = `https://4t7cwt8tyk.execute-api.us-east-2.amazonaws.com/staging/api`;
} else if (process.env.REACT_APP_ENV === "prod") {
  apiHostForChatbot = `https://chatbot.ruebarue.com/prod/api`;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("api_token")}`,
  },
  withCredentials: false,
  baseURL: `${apiHost}/v1`,
});

const axiosInstanceV2 = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("api_token")}`,
  },
  withCredentials: true,
  baseURL: `${apiHost}/v2`,
});

axiosInstanceV2.interceptors.request.use(function (config) {
  return config;
});

const axiosInstanceForChatbot = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("api_token")}`,
  },
  withCredentials: true,
  baseURL: `${apiHostForChatbot}`,
});

export default axiosInstance;
export const httpV2 = axiosInstanceV2;
export const host = apiHost;
export const httpAI = axiosInstanceForChatbot;
