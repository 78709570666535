// Import package components
import { useContext } from "react";
import UIkit from "uikit";

import { AppContexts } from "../../providers";
import Dialog from "../common/Dialog";

const PropertyDelete = () => {
  const { rental, deleteRental } = useContext(AppContexts.RentalsContext);

  const onDelete = async () => {
    await deleteRental(rental.rental_id);
    // UIkit.modal("#modal-property-delete").hide();
  };

  return (
    <>
      <Dialog id="modal-property-delete">
        <div className="uk-modal-dialog uk-modal-body">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
          ></button>
          <h2 className="uk-modal-title">Delete Home Guide</h2>
          <p>Are you sure you want to delete?</p>
          <p className="uk-text-right">
            <button
              className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
              type="button"
            >
              Cancel
            </button>
            <button
              className="uk-button hms-btn hms-gray-btn"
              type="button"
              onClick={onDelete}
            >
              Delete
            </button>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default PropertyDelete;
