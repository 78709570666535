// Import package components
import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { Image, Flex, Select, SelectOption } from "uikit-react";
import moment from "moment";
import { useCookies } from "react-cookie";
import { Link } from "../../ui";

import { AppContexts } from "../../providers";

// Import common components
import RecordsPerPage from "../common/RecordsPerPage";
import Pagination from "../common/Pagination";
import AddTextLine from "../common/AddTextLine";

// Import message-templates components
import SavedTemplateAdd from "./add";
import SavedTemplateDelete from "./delete";
import classnames from "classnames";

const folderMap = [
  "Alerts",
  "Scheduler",
  "Extend Guest Stays",
];

const MessageTemplates = () => {
  const [criteria, setCriteria] = useState({
    search_name: "",
    search_folder: "",
    sort: "updated_at",
    page: 0,
    tag: "all",
  });

  const {
    templatesCtx: { get, templates, setIsNew, initMsgTemplate, setMsgTemplate },
  } = useContext(AppContexts.TemplatesContext);

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const msgTemplates = useMemo(() => {
    if (Object.keys(templates).length == 0) {
      return [];
    } else {
      if (!templates.msg_templates) {
        return [];
      } else {
        return templates.msg_templates
          .filter((temp) => {
            if (
              temp.name
                .toLowerCase()
                .search(criteria.search_name.toLowerCase().trim()) >= 0
            )
              return true;
            return false;
          })
          .filter((temp) => {
            if (
              criteria.search_folder === "Scheduler"
                ? temp.folder.search("Scheduler") >= 0 ||
                  temp.folder.search("scheduledMsg") >= 0
                : criteria.search_folder === "Alerts"
                  ? temp.folder.search("Alerts") >= 0 ||
                    temp.folder.search("alert") >= 0
                  : criteria.search_folder === "Schedule Ahead"
                    ? temp.folder.search("Schedule Ahead") >= 0 ||
                      temp.folder.search("scheduleAhead") >= 0
                    : criteria.search_folder === "Extend Guest Stays"
                      ? temp.folder.search("Extend Guest Stays") >= 0 ||
                        temp.folder.search("extendYourStay") >= 0
                      : true
            )
              return true;
            return false;
          })
          .sort((prev, next) => {
            if (criteria.sort === "updated_at")
              return next.updated_at?.localeCompare(prev.updated_at);
            if (criteria.sort === "name")
              return prev.name.localeCompare(next.name);
            return true;
          });
      }
    }
  }, [templates, criteria]);

  const visibleMsgTemplates = useMemo(
    () =>
      msgTemplates.slice(
        criteria.page * recordsPerPage,
        (criteria.page + 1) * recordsPerPage,
      ),
    [msgTemplates, criteria, recordsPerPage],
  );

  useEffect(() => {
    get(["msg_templates"]);
    document.title = "Message Templates - RueBaRue";
  }, []);

  const handleAddTemplate = useCallback(() => {
    setIsNew(true);
    initMsgTemplate();
  }, []);

  const handleEditTemplate = useCallback((template) => {
    setIsNew(false);
    setMsgTemplate(template);
  });

  const handleDeleteTemplate = useCallback((template) => {
    setMsgTemplate(template);
  });

  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((criteria) => ({ ...criteria, [key]: value }));
  });

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  });

  const templateRenderer = useCallback(
    (template) => (
      <tr key={template.id}>
        <td>
          <div className="semi-bold-font">{template.name}</div>
        </td>
        <td>
          {template.folder === "scheduledMsg"
            ? "Scheduler"
            : template.folder === "alert"
              ? "Alerts"
              : template.folder === "scheduleAhead"
                ? "Schedule Ahead"
                : template.folder === "extendYourStay"
                  ? "Extend Guest Stays"
                  : template.folder}
        </td>
        <td className="hms-action-btn">
          <div className="uk-flex uk-flex-top">
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #message-templates-modal"
                onClick={() => handleEditTemplate(template)}
                uk-tooltip="Edit"
              >
                <Image src="/images/edit.svg" />
              </button>
            </div>
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-template-delete"
                onClick={() => handleDeleteTemplate(template)}
                uk-tooltip="Delete"
              >
                <Image src="/images/delete.svg" />
              </button>
            </div>
          </div>
        </td>
      </tr>
    ),
    [],
  );

  return (
    <>
      <section id="schedule-a-message">
        <AddTextLine feature="Message Templates" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Message Templates</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #message-templates-modal"
                onClick={handleAddTemplate}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Message Template
              </button>
              <img
                className="info-icon"
                src="/images/info-icon.svg"
                alt="info"
                uk-tooltip="Use message templates in Alerts, Extend Guest Stays and Scheduler"
              />              
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <Flex alignment="between top" className="guest-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default uk-form-custom uk-margin-small-right">
                        {criteria.search_name && criteria.search_name !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() =>
                              handleCriteriaChange("search_name", "")
                            }
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name"
                          value={criteria.search_name}
                          onChange={(event) =>
                            handleCriteriaChange(
                              "search_name",
                              event.target.value,
                            )
                          }
                        />
                      </div>
                      <div uk-form-custom="target: > * > span:first-child">
                        <Select
                          value={
                            criteria.search_folder === null
                              ? "All Folders"
                              : criteria.search_folder
                          }
                          onChange={(e) =>
                            handleCriteriaChange(
                              "search_folder",
                              e.target.value === "All" ? null : e.target.value,
                            )
                          }
                        >
                          <SelectOption value="">All</SelectOption>
                          {folderMap.map((folder, index) => (
                            <SelectOption value={folder} key={index}>
                              {folder}
                            </SelectOption>
                          ))}
                        </Select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="hms-sortby-btn">
                    <button className="uk-button" type="button">
                      SORT BY<span uk-icon="icon: triangle-down"></span>
                    </button>
                    <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "updated_at",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() =>
                              handleCriteriaChange("sort", "updated_at")
                            }
                          >
                            Updated At
                          </Link>
                        </li>
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === "name",
                          })}
                        >
                          <Link
                            href="#"
                            onClick={() => handleCriteriaChange("sort", "name")}
                          >
                            Name
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Flex>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-expand">Name</th>
                    <th className="uk-width-115">Folder</th>
                    <th className="uk-width-205 actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleMsgTemplates.length > 0 ? (
                    visibleMsgTemplates.map(templateRenderer)
                  ) : (
                    <tr>
                      <td colSpan={3} style={{ padding: "30px 25px" }}>
                        No message templates matching your current search or
                        filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={msgTemplates.length}
              index={criteria.page}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <SavedTemplateAdd />
      <SavedTemplateDelete />
    </>
  );
};

export default MessageTemplates;
