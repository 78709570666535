// Import package components
import {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import { Image, Flex } from "uikit-react";
import UIkit from "uikit";
import { useCookies } from "react-cookie";
import classnames from "classnames";
import Papa from "papaparse";
import { useLocation, useHistory } from "react-router-dom";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import PageTitle from "../common/PageTitle";
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import TableLoading from "../common/Loading/table_loading";
import { Link } from "../../ui";
import AddTextLine from "../common/AddTextLine";

// Import contact components
import ContactAdd from "./add";
import ContactDelete from "./delete";
import Message from "./message";
import Tags from "../common/Tags";
import Properties from "../common/Properties";
import TagsList from "./tag_list";
import NoteAdd from "../notes/note-add";
import { useDeepCompareEffect } from "../../hook";

const sortOptions = [
  { key: "updated_at", title: "Last Updated" },
  { key: "first_name", title: "First Name" },
  { key: "last_name", title: "Last Name" },
];

const defaultCriteria = {
  search_term: "",
  sort: "updated_at",
  tag: "",
  type: "",
  property: "",
  per: "20",
  page: 0,
};

const parseQueryString = (queryString) => {
  return {
    ...defaultCriteria,
    ...Object.fromEntries(new URLSearchParams(queryString)),
  };
};

const Contacts = () => {
  const location = useLocation();
  const history = useHistory();
  const [criteria, setCriteria] = useState(() => {
    return location.search
      ? parseQueryString(location.search)
      : defaultCriteria;
  });
  const [selectedFlags, setSelectedFlags] = useState([]);

  const contactContext = useContext(AppContexts.ContactsContext);
  const settingsContext = useContext(AppContexts.SettingsContext);
  const rentalContext = useContext(AppContexts.RentalsContext);
  // const notesContext = useContext(AppContexts.NotesContext);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const inputRef = useRef(null);

  const { listContacts, totalCnt, flagIsProcessing, updateContact } =
    contactContext;
  const contacts = useMemo(() => listContacts || [], [listContacts]);

  useEffect(() => {
    document.title = "Contacts - RueBaRue";
    settingsContext.get(["tags"]);
    rentalContext.get();
  }, []);

  useEffect(() => {
    setCriteria((it) => ({ ...it, per: recordsPerPage }));
  }, [recordsPerPage]);

  useDeepCompareEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => contactContext.searchContacts(criteria), 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [criteria]);

  useEffect(() => {
    if (selectedFlags.length !== contacts.length)
      setSelectedFlags(Array(contacts.length).fill(false));
  }, [contacts.length, selectedFlags.length]);

  useEffect(() => {
    contactContext.setSelectedContacts(
      selectedFlags
        .map((selected, index) => (selected ? contacts[index] : null))
        .filter((contact) => contact),
    );
  }, [selectedFlags, contacts]);

  const propertyValues = useMemo(
    () =>
      (rentalContext.rentals || [])
        .filter((r) => r.pms_id)
        .sort((a, b) => a.pms_id.localeCompare(b.pms_id)) || [],
    [rentalContext.rentals],
  );
  const tagValues = useMemo(
    () =>
      settingsContext.settings.tags?.sort((a, b) =>
        a.name.localeCompare(b.name),
      ) || [],
    [settingsContext.settings],
  );

  const { selectedAll, selectedAny } = useMemo(() => {
    const hasFlags = selectedFlags.length > 0;
    const selectedAll = hasFlags && selectedFlags.every((checked) => checked);
    const selectedAny = hasFlags && selectedFlags.some((checked) => checked);

    return { selectedAll, selectedAny };
  }, [selectedFlags]);

  const updateQueryParams = (newCriteria) => {
    const searchParams = new URLSearchParams(newCriteria).toString();
    history.replace(`/contacts?${searchParams}`);
  };

  const handleAddContact = useCallback(() => {
    contactContext.setIsNew(true);
    contactContext.initContact();
  }, []);

  const handleEditContact = useCallback((contact) => {
    contactContext.setIsNew(false);
    contactContext.setContact(contact);
  });

  const handleSelected = useCallback(() => {
    contactContext.setBatchFlag(true);
  }, []);

  const handleSelectedTags = useCallback((tags, contact) => {
    let temp = { ...contact, tags };
    updateContact(temp);
  }, []);

  const handleSelectedRentals = useCallback((rentals, contact) => {
    let temp = { ...contact, rentals };
    updateContact(temp);
  }, []);

  const handleMessage = useCallback((contact) => {
    contactContext.setBatchFlag(false);
    contactContext.setContact(contact);
  }, []);

  const handleDeleteContact = useCallback((contact) => {
    contactContext.setBatchFlag(false);
    contactContext.setContact(contact);
  });

  const handleCriteriaChange = (key, value) => {
    const newCriteria = { ...criteria, [key]: value };
    setCriteria(newCriteria);
    updateQueryParams(newCriteria);
  };

  const handleIndexChanged = useCallback((newPageIndex) => {
    setSelectedFlags([]);
    handleCriteriaChange("page", newPageIndex);
  });

  const handleSelect = useCallback((checked, index) => {
    setSelectedFlags((flags) => [
      ...flags.slice(0, index),
      checked,
      ...flags.slice(index + 1),
    ]);
  }, []);

  // const handleAddNote = (contact) => {
  //   notesContext.getNoteByItem(contact.id, 'contact');
  //   contactContext.setContact(contact);
  // }

  const contactRenderer = useCallback(
    (contact, index) => (
      <tr key={contact?.id}>
        <td>
          <div className="autosend-checkbox">
            <label htmlFor={`autoSendCheckbox${index}`}>
              <input
                type="checkbox"
                id={`autoSendCheckbox${index}`}
                checked={selectedFlags[index]}
                onChange={(event) => handleSelect(event.target.checked, index)}
              />
            </label>
          </div>
        </td>
        <td>
          <div className="contact-td">
            <span
              uk-tooltip={`title: ${contact?.category
                .toLowerCase()
                .replace(/\b\w/g, (s) =>
                  s.toUpperCase(),
                )}; pos: bottom-top; cls: uk-active contact-tooltip`}
              style={{ width: "32px", height: "32px" }}
            >
              <img
                src={`images/${contact?.category.toLowerCase()}-active.svg`}
                alt="active.svg"
                width="30px"
                height="30px"
              />
            </span>
            <div>
              <div className="semi-bold-font">
                {contact?.first_name} {contact?.last_name}
              </div>
              <div className="secondary">{contact?.formatted_phone}</div>
              <div className="secondary">{contact?.email}</div>
            </div>
          </div>
        </td>
        <td>
          {contact?.category
            .toLowerCase()
            .replace(/\b\w/g, (s) => s.toUpperCase())}
        </td>
        <td className="hms-add-tag">
          <Properties
            value={contact?.rentals}
            onChange={(editedTags) =>
              handleSelectedRentals(editedTags, contact)
            }
          />
        </td>
        <td className="hms-add-tag">
          <Tags
            all={tagValues}
            value={contact?.tags}
            onChange={(event) => handleSelectedTags(event, contact)}
          />
        </td>
        <td className="hms-action-btn">
          <div className="uk-flex uk-flex-top">
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-contact-message"
                onClick={() => handleMessage(contact)}
                uk-tooltip="Send a Message"
              >
                <Image src="/images/sms.svg" />
              </button>
              {/* <button
                className="uk-button"
                uk-toggle="target: #modal-guest-note"
                onClick={() => handleAddNote(contact)}
                uk-tooltip="Notes"
              >
                {
                  contact?.notes?.length > 0
                  ? (<Image src="/images/notes-filled.svg" />)
                  : (<Image src="/images/notes.svg" />)
                }
              </button> */}
              <button
                className="uk-button"
                uk-toggle="target: #modal-contact-add"
                onClick={() => handleEditContact(contact)}
                uk-tooltip="Edit"
              >
                <Image src="/images/edit.svg" />
              </button>
            </div>
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-contact-delete"
                onClick={() => handleDeleteContact(contact)}
                uk-tooltip="Delete"
              >
                <Image src="/images/delete.svg" />
              </button>
            </div>
          </div>
        </td>
      </tr>
    ),
    [selectedFlags],
  );

  const handleSelectAll = useCallback(
    (event) =>
      setSelectedFlags((flags) => flags.map(() => event.target.checked)),
    [selectedFlags],
  );

  const handleImport = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // Store the current input value
    Papa.parse(fileObj, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        let data = results.data.map((data) => {
          if (data.category?.toLowerCase() == "leads") {
            return { ...data, category: "lead" };
          } else {
            return data;
          }
        });

        // Check if there's any data
        if (!data.length) {
          UIkit.notification("No content", "error");
          return;
        }

        // Validate required columns
        const columns = Object.keys(data[0]);
        let requiredColumns = [
          "category",
          "email",
          "first_name",
          "last_name",
          "phone",
        ];
        let missingColumns = requiredColumns.filter(
          (col) => !columns.includes(col),
        );

        if (missingColumns.length) {
          UIkit.notification(
            `Missing Columns: ${missingColumns.join(", ")}`,
            "error",
          );
          return;
        }

        // Split the data into chunks, e.g., 100 rows per chunk
        let chunkSize = 10; // Adjust chunk size according to your API limits
        let chunks = [];
        for (let i = 0; i < data.length; i += chunkSize) {
          chunks.push(data.slice(i, i + chunkSize));
        }

        try {
          // Create promises for each chunk import
          const promises = chunks.map((chunk) =>
            contactContext.importContacts(chunk),
          );

          // Wait for all promises to resolve using Promise.all
          const results = await Promise.all(promises);

          // Process results to calculate duplicates and errors
          let totalDuplicates = 0;
          let totalErrors = [];

          results.forEach((result) => {
            totalDuplicates += result?.exists?.length || 0;
            if (result?.errors?.length) {
              totalErrors = totalErrors.concat(result.errors);
            }
          });

          // Display notifications based on results
          if (totalDuplicates > 0) {
            UIkit.notification(
              `${totalDuplicates} records already exist.`,
              "error",
            );
          }

          if (totalErrors.length > 0) {
            UIkit.notification(
              `Failed: ${totalErrors.length} records.`,
              "error",
            );
          } else if (totalDuplicates === 0 && totalErrors.length === 0) {
            UIkit.notification("Contacts Added Successfully", "success");
          }

          // Refresh the contact list after the import is done
          await contactContext.searchContacts(criteria);
        } catch (error) {
          // Handle any error that occurs during the chunked imports
          console.error("Error importing contacts:", error);
          UIkit.notification(
            "Error importing contacts. Please try again.",
            "error",
          );
        }

        // Reset input field and trigger input event
        event.target.value = "";
        const newEvent = new Event("input", { bubbles: true });
        event.target.dispatchEvent(newEvent);
      },
    });
  };

  const handleClickImport = () => {
    inputRef.current.click();
  };

  return (
    <>
      <section id="view-add-contacts">
        <AddTextLine feature="Contacts" />
        <PageTitle title="Contacts" />

        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #modal-contact-add"
                onClick={handleAddContact}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Contact
              </button>
              <button
                className="uk-button hms-btn hms-orange-btn"
                uk-toggle="target: #modal-contact-message"
                disabled={!selectedAny}
                onClick={handleSelected}
              >
                <span uk-icon="icon: mail; ratio: 1"></span> Message
              </button>
              <TagsList all={tagValues} disabled={!selectedAny} />
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                onChange={handleImport}
                accept=".csv"
              />
              <button
                className="uk-button hms-btn hms-white-btn"
                onClick={handleClickImport}
              >
                <span uk-icon="icon: arrow-up; ratio: 1"></span> Import
              </button>
              <a
                className="uk-margin-small-right"
                href="/samples/contact-import-sample.csv"
                style={{ color: "black" }}
                target="_blank"
              >
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  uk-tooltip="Click to download sample comma separated file (CSV) to bulk import contacts"
                />
              </a>
              <button
                className="uk-button hms-btn hms-white-btn"
                uk-toggle="target: #modal-contact-delete"
                disabled={!selectedAny}
                onClick={handleSelected}
              >
                <span uk-icon="icon: trash; ratio: 0.7"></span> Delete
              </button>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card">
            <div className="uk-overflow-auto">
              <form action="" className="uk-form">
                <Flex
                  alignment="between top"
                  className="guest-form-top-filters"
                >
                  <div className="hms-form">
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select
                          value={criteria.type}
                          onChange={(e) =>
                            handleCriteriaChange("type", e.target.value)
                          }
                        >
                          <option value="">All Types</option>
                          <option value="owner">Owner</option>
                          <option value="housekeeper">Housekeeper</option>
                          <option value="maintenance">Maintenance</option>
                          <option value="vendor">Vendor</option>
                          <option value="lead">Lead</option>
                          <option value="other">Other</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    {!(settingsContext.loading || rentalContext.loading) && (
                      <div className="uk-inline">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={criteria.property}
                            onChange={(e) =>
                              handleCriteriaChange("property", e.target.value)
                            }
                          >
                            <option value="">All Properties</option>
                            {propertyValues.map((property, index) => (
                              <option
                                value={property.pms_id}
                                key={`property_option_${property.pms_id}`}
                              >
                                {property.pms_id}
                              </option>
                            ))}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span></span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    )}
                    {!(settingsContext.loading || rentalContext.loading) && (
                      <div className="uk-inline">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={criteria.tag}
                            onChange={(e) =>
                              handleCriteriaChange("tag", e.target.value)
                            }
                          >
                            <option value="">All Tags</option>
                            {tagValues.map((tag, index) => (
                              <option
                                value={tag.name}
                                key={`tag_option_${index}`}
                              >
                                {tag.name}
                              </option>
                            ))}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span></span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {criteria.search_term ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() =>
                              handleCriteriaChange("search_term", "")
                            }
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name or Property ID"
                          value={criteria.search_term}
                          onChange={(e) =>
                            handleCriteriaChange("search_term", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="hms-sortby-btn">
                      <button className="uk-button" type="button">
                        SORT BY<span uk-icon="icon: triangle-down"></span>
                      </button>
                      <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                        <ul className="uk-nav uk-dropdown-nav">
                          {sortOptions.map((option, idx) => (
                            <li
                              className={classnames({
                                "uk-active": criteria.sort === option.key,
                              })}
                              key={`sort_option_${idx}`}
                            >
                              <Link
                                href="#"
                                onClick={() =>
                                  handleCriteriaChange("sort", option.key)
                                }
                              >
                                {option.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Flex>
              </form>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="uk-table-shrink">
                      <label
                        htmlFor="checkboxContactAll"
                        className="checkboxContact"
                      >
                        <input
                          type="checkbox"
                          id="checkboxContactAll"
                          checked={selectedAll}
                          onChange={handleSelectAll}
                        />
                      </label>
                    </th>
                    <th className="uk-table-expand name-field">
                      Name/Phone/Email
                    </th>
                    <th className="uk-table-expand">Type</th>
                    <th className="uk-table-expand">Properties</th>
                    <th className="uk-table-expand">Tags</th>
                    <th className="uk-width-205 actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {flagIsProcessing == true ? (
                    TableLoading(6)
                  ) : contacts.length > 0 ? (
                    contacts.map(contactRenderer)
                  ) : (
                    <tr>
                      <td colSpan={9} style={{ padding: "30px 25px" }}>
                        No contacts matching your current search or filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={totalCnt}
              index={Number(criteria.page)}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <Message />
      <ContactAdd />
      <ContactDelete />
      <NoteAdd itemId={contactContext.contact.id} itemType="contact" />
    </>
  );
};

export default Contacts;
