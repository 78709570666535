// Import package components
import moment from "moment";
import { createContext, useState } from "react";
import { useCookies } from "react-cookie";

import ReservationsService from "../services/reservations.service";

export const ReservationsContext = createContext();
export const ReservationsProvider = ({ children }) => {
  const [flagIsEditing, setIsEditing] = useState(false);
  const [flagIsNew, setIsNew] = useState(false);
  const [flagIsProcessing, setIsProcessing] = useState(false);
  const [flagIsLoading, setIsLoading] = useState(false);
  const [sendingMsg, setSendingMsg] = useState(null);
  const [listReservations, setReservations] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [reservation, setReservation] = useState({});
  const [historyReservation, setHistory] = useState([]);
  const [guestBook, setGuestBook] = useState([]);
  const [survey, setSurvey] = useState([]);
  const [gettingSurveyResponse, setGettingSurveyResponse] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState();
  const [fivestar, setFivestar] = useState([]);

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const contextReservations = {
    flagIsEditing,
    setIsEditing,
    flagIsNew,
    setIsNew,
    reservation,
    setReservation,
    flagIsProcessing,
    listReservations,
    flagIsLoading,
    setIsLoading,
    sendingMsg,
    setSendingMsg,
    setReservations,
    totalCnt,
    historyReservation,
    setHistory,
    guestBook,
    setGuestBook,
    survey,
    setSurvey,
    gettingSurveyResponse,
    surveyResponse,
    setSurveyResponse,
    fivestar,
    setFivestar,

    initReservation: () => {
      setReservation({
        guest_reservation_id: 0,
        reservation_id: "",
        first_name: "",
        last_name: "",
        check_in: moment().format("YYYY-MM-DD"),
        check_out: moment().format("YYYY-MM-DD"),
        pms_id: "",
        email: "",
        phone: "",
        door_code: "",
      });
    },

    editReservation: (reservation) => {
      setReservation(reservation);
    },

    insertReservation: async (objReservation) => {
      try {
        const res = await ReservationsService.insertReservation(objReservation);
        setReservation({ ...reservation, ...res.data });
        setReservations([...listReservations, res.data]);
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    updateReservation: async (id, objReservation) => {
      try {
        const res = await ReservationsService.updateReservation(
          id,
          objReservation,
        );
        setReservation({ ...reservation, ...res.data });
        let updatedReservations = [...listReservations];
        const idx = updatedReservations.findIndex(
          (reservation) =>
            reservation.guest_reservation_id.toString() ===
            res.data.guest_reservation_id.toString(),
        );
        if (idx > -1) {
          let updated = updatedReservations[idx];
          Object.keys(res.data).forEach((key) => {
            updated[key] = res.data[key];
          });
          updatedReservations[idx] = updated;
        }
        setReservations(updatedReservations);
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    importReservations: async (reservations) => {
      try {
        const res = await ReservationsService.importReservations(reservations);
        setReservations((prev) => [...prev, ...res.data.saved]);
        return res.data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    deleteReservation: async (objReservation) => {
      setIsProcessing(true);
      const res = await ReservationsService.deleteReservation(objReservation);
      setReservations(
        listReservations.filter(
          (indexReservation) => indexReservation.id !== objReservation.id,
        ),
      );
      setIsProcessing(false);
    },

    deleteReservations: async (objReservations) => {
      const queries = objReservations.map((objReservation) =>
        ReservationsService.deleteReservation(objReservation),
      );
      const result = await Promise.all(queries);
      setReservations(
        listReservations.filter(
          (indexReservation) => !objReservations.includes(indexReservation),
        ),
      );
    },

    searchReservation: async (criteria) => {
      setIsProcessing(true);
      const res = await ReservationsService.searchReservation(criteria);
      setReservations(res.data.reservations);
      setTotalCnt(res.data.total);
      setIsProcessing(false);
    },

    createSMS: async (sms) => {
      setIsProcessing(true);
      const res = await ReservationsService.createSMS(sms);
      setIsProcessing(false);
      return res.data;
    },

    getSeletedReservation: async (objReservation) => {
      setIsProcessing(true);
      const res = await ReservationsService.getSelectedReservation(
        objReservation.guest_reservation_id,
      );
      setIsProcessing(false);
      return res;
    },

    getReservationByPhone: async (reservation) => {
      if (!reservation.formatted_phone) {
        setHistory(reservation);
        return;
      }
      const res = await ReservationsService.getReservationByPhone(
        reservation.formatted_phone,
      );

      if (!!res.data) {
        await setHistory(res.data);
      }
    },

    getGuestBook: async (reservation_id) => {
      const res = await ReservationsService.getGuestBook(reservation_id);

      if (!!res.data) {
        await setGuestBook(res.data);
      }
    },

    sendScheduledMessage: async (reservation_id, msg_id, contact = null) => {
      setSendingMsg({
        reservation_id,
        msg_id,
      });
      try {
        const res = await ReservationsService.sendScheduledMessage(
          reservation_id,
          msg_id,
          contact,
        );
        setSendingMsg(null);
        return res.data;
      } catch (e) {
        setSendingMsg(null);
        return e;
      }
    },
    
    getSurvey: async (guest_reservation_id) => {
      try {
        let res = await ReservationsService.getSurvey(guest_reservation_id);

        if (res.data) {
          setSurvey(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    },

    getSurveyResponse: async (survey_id, survey_results_id) => {
      try {
        setGettingSurveyResponse(true);
        let res = await ReservationsService.getSurveyResponse(survey_id, survey_results_id);

        if (res.data) {
          setSurveyResponse(res.data);
        }
        setGettingSurveyResponse(false);
      } catch (error) {
        setGettingSurveyResponse(false);
        console.log(error);
      }
    },

    getFivestar: async (guest_reservation_id) => {
      try {
        let res = await ReservationsService.getFivestar(guest_reservation_id);
        if (res.data) {
          setFivestar(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <ReservationsContext.Provider value={contextReservations}>
      {children}
    </ReservationsContext.Provider>
  );
};
