import { createContext, useState } from "react";
import notesService from "../services/notes.service";

export const NotesContext = createContext();
export const NotesProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [notes, setNotes] = useState([]);
  const [list, setList] = useState({
    paginated: [],
    total: 0,
  });

  let notesCtx = {
    loading,
    setLoading,
    error,
    setError,
    notes,
    setNotes,
    list,
    setList,

    getAll: async (criteria) => {
      setLoading(true);
      let result = await notesService.getAll(criteria);
      if (result.data) {
        setList(result.data);
      }
      setLoading(false);
    },

    getNoteByItem: async (id, type) => {
      setLoading(true);
      let result = await notesService.getNotesByItem(id, type);
      if (result.data) {
        setNotes(result.data);
      }
      setLoading(false);
    },

    createNote: async (objNote) => {
      try {
        let result = await notesService.create(objNote);
        if (result.data) {
          setNotes((prev) => [...prev, result.data]);
          return result.data;
        }

        return result.data;
      } catch (e) {
        if (e?.response?.data?.message) setError(e.response.data.message);
        return null;
      }
    },

    updateNote: async (objNote) => {
      let result = await notesService.update(objNote);
      if (result.data) {
        setNotes((prev) =>
          prev.map((item) =>
            +item.id === +result.data.id
              ? { ...result.data, updated_at: item.updated_at }
              : item,
          ),
        );
        return result.data;
      }
    },

    deleteNote: async (id) => {
      setLoading(true);
      let index = notes.findIndex((note) => note.id == id);
      let result = await notesService.delete(id);
      if (result.data) {
        setNotes((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
      }
      setLoading(false);
    },
  };

  return (
    <NotesContext.Provider value={notesCtx}>{children}</NotesContext.Provider>
  );
};
