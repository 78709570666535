import { createContext, useState } from "react";

import inboxService from "../services/inbox.service";
import MessagesService from "../services/messages.service";
import moment from "moment";
import { io } from "socket.io-client";

const NO_SELECTION = -1;

const socket = io("https://ruebarue-socket-proxy.herokuapp.com", {
  transports: ["websocket"],
  reconnection: true,
});

const defaultRecipient = {
  first_name: "",
  last_name: "",
  email: "",
  schedule: [
    {
      weekday: 2,
      start_time: "2001-01-02 09:00:00",
      end_time: "2001-01-02 17:00:00",
    },
    {
      weekday: 3,
      start_time: "2001-01-03 09:00:00",
      end_time: "2001-01-03 17:00:00",
    },
    {
      weekday: 4,
      start_time: "2001-01-04 09:00:00",
      end_time: "2001-01-04 17:00:00",
    },
    {
      weekday: 5,
      start_time: "2001-01-05 09:00:00",
      end_time: "2001-01-05 17:00:00",
    },
    {
      weekday: 6,
      start_time: "2001-01-06 09:00:00",
      end_time: "2001-01-06 17:00:00",
    },
  ],
};

export const InboxContext = createContext();
export const InboxProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isSendingFlag, setIsSending] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [settings, setSettings] = useState(null);
  const [inbox, setInbox] = useState(null);
  const [threadCnt, setThreadCnt] = useState(0);
  const [reservation, setReservation] = useState(null);
  const [filters, setFilters] = useState({});
  const [selectedThreadId, setSelectedThreadId] = useState(0);
  const [threadDetail, setThreadDetail] = useState({});

  const [recipient, setRecipient] = useState(
    JSON.parse(JSON.stringify(defaultRecipient)),
  );
  const [deletedRecipient, setDeletedRecipient] = useState(null);
  const [recipientPending, setRecipientPending] = useState(false);
  const [invitePending, setInvitePending] = useState(false);

  let contextInbox = {
    loading,
    isSendingFlag,
    isNew,
    setIsNew,
    inbox,
    settings,
    setInbox,
    reservation,
    setReservation,
    filters,
    setFilters,
    selectedThreadId,
    setSelectedThreadId,
    setThreadDetail,
    threadDetail,
    socket,
    threadCnt,
    setThreadCnt,

    recipient,
    setRecipient,
    deletedRecipient,
    setDeletedRecipient,
    recipientPending,
    setRecipientPending,
    invitePending,
    setInvitePending,

    get: async () => {
      setLoading(true);
      let resp = await inboxService.get();
      setSettings(resp.data);
      setLoading(false);
    },
    initRecipient: () => {
      setRecipient(defaultRecipient);
    },
    createRecipient: async (recipient) => {
      setRecipientPending(true);
      inboxService
        .createRecipient(recipient)
        .then((resp) => {
          setSettings({ ...settings, recipients: resp.data });
          setRecipientPending(false);
          setRecipient(JSON.parse(JSON.stringify(defaultRecipient)));
          return resp.data;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    updateRecipient: async (recipient) => {
      setRecipientPending(true);
      inboxService
        .updateRecipient(recipient)
        .then((resp) => {
          setSettings({ ...settings, recipients: resp.data });
          setRecipientPending(false);
          setRecipient(JSON.parse(JSON.stringify(defaultRecipient)));
          return resp.data;
        })
        .catch((err) => {
          console.warn(err);
          setRecipientPending(false);
        });
    },
    deleteRecipient: async (recipient) => {
      setRecipientPending(true);
      inboxService
        .deleteRecipient(recipient.recipient_id)
        .then((resp) => {
          setSettings({ ...settings, recipients: resp.data });
          setRecipientPending(false);
          setRecipient(JSON.parse(JSON.stringify(defaultRecipient)));
        })
        .catch((err) => {
          console.warn(err);
          setRecipientPending(false);
        });
    },
    inviteRecipient: async (recipient) => {
      setInvitePending(true);
      inboxService
        .inviteRecipient(recipient.recipient_id)
        .then(async (resp) => {
          await setSettings({ ...settings, recipients: resp.data });
          setInvitePending(false);
        })
        .catch((err) => {
          console.warn(err);
          setInvitePending(false);
        });
    },
    refresh: async (pageNo, filter, pageSize) => {
      setLoading(true);
      try {
        // TODO: update inbox page & filter
        const response = await inboxService.getInboxes(pageNo, filter, pageSize);
        const { error, page, count } = response.data;

        if (error) {
          throw error;
        }

        setInbox(page);
        setThreadCnt(count);

        // if (filter) {
        //   setSelectedThreadId(NO_SELECTION);
        //   setThreadDetail({});
        // }
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    },
    selectThread: async (threadId) => {
      setLoading(true);

      try {
        const response = await inboxService.getInboxThread(threadId);
        const { error, thread } = response.data;
        if (inbox) {
          let updatedInbox = inbox.map((item) => {
            if (
              item.id.toString() === thread.id.toString() &&
              item.meta_values?.indexOf("unread") >= 0
            ) {
              return {
                ...item,
                meta_values: item.meta_values?.replace("unread", "read"),
              };
            }
            return item;
          });
          setInbox(updatedInbox);
        }

        setThreadDetail(thread);
      } catch (err) {
        console.log("error:", err);
        setLoading(false);
      }

      setSelectedThreadId(threadId);
      setLoading(false);
    },
    getThreadDetail: async (thread_id) => {
      try {
        const response = await inboxService.getInboxThread(thread_id);
        const { error, thread } = response.data;
        setThreadDetail(thread);
        return thread;
      } catch (err) {
        console.warn(err);
      }
    },
    setMeta: async (thread_id, data) => {
      let result = await inboxService.setMeta(thread_id, data);
      return result.data;
    },
    sendMessage: async (objMessage) => {
      setIsSending(true);
      const result = await MessagesService.sendMessage(objMessage);
      if (result.data) {
        setThreadDetail({ ...threadDetail, ...result.data });
      }
      setIsSending(false);
      return result.data;
    },
  };

  return (
    <InboxContext.Provider value={contextInbox}>
      {children}
    </InboxContext.Provider>
  );
};
