import React, { useContext, useEffect, useState } from "react";
import { AppContexts } from "../../../providers";
import MainBody from "../MainBody";

function MessageComposer() {
  const [guestQuestion, setGuestQuestion] = useState("");
  const [suggestedMessage, setSuggestedMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [escEnable, setEscEnable] = useState(true);
  const { user } = useContext(AppContexts.AuthContext);
  console.log(user);
  const { loading: isLoading, compose } = useContext(AppContexts.ChatbotContext);

  const composeMessage = async () => {
    if (!guestQuestion.trim()) return;

    setErrorMessage("");
    setSuggestedMessage("");

    try {
      const response = await compose(user.account_id, user.user_id, guestQuestion);
      setSuggestedMessage(response.composed_response);
    } catch (error) {
      console.error("Error composing message:", error);
      setErrorMessage("Sorry, we couldn't generate a message. Please try again.");
    }
  };

  const copyMessage = () => {
    navigator.clipboard.writeText(suggestedMessage).then(
      () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false); // Reset the button after 2 seconds
        }, 2000);
      },
      (err) => console.error("Could not copy message:", err)
    );
  };

  const handleQuestionChange = (e) => {
    setGuestQuestion(e.target.value);
  }
  const closePopup = () => {
    const popup = document.getElementById('custom-popup');
    popup.setAttribute('hidden', true);
  }

  useEffect(() => {
    document.title = "AI Assistant - RueBaRue";
  }, []);

  // useEffect(() => {
  //   const modal = document.getElementById(parentModalId);

  //   const handleKeyDown = (event) => {
  //     if (event.key === "Escape") {
  //       console.log("Escape key pressed, preventing UIKit from closing modal.");
  //       event.preventDefault();
  //       event.stopImmediatePropagation(); // Prevent UIKit's default behavior
  //     }
  //   };
    
  //   if (!escEnable) {
  //     if (modal) {
  //       modal.addEventListener("keydown", handleKeyDown, true);
  //     }
  
  //     return () => {
  //       if (modal) {
  //         modal.removeEventListener("keydown", handleKeyDown, true);
  //       }
  //     };
  //   } else {
  //     if (modal) {
  //       modal.removeEventListener("keydown", handleKeyDown, true);
  //     }
  //   }
  // }, [parentModalId, escEnable]);

  // useEffect(() => {
  //   const popup = document.getElementById('custom-popup');
  //   const observer = new MutationObserver((mutationList) => {
  //     for (const mutation of mutationList) {
  //       if (mutation.attributeName === "hidden") {
  //         const value = popup.getAttribute('hidden');
  //         console.log(value);
  //         setEscEnable(value === "true" ? true : false);
  //       }
  //     }
  //   });
  //   observer.observe(popup, { attributeFilter: ['hidden'] });

  //   return () => {
  //     observer.disconnect();
  //   }
  // }, [])

  return (
    // <div id="custom-popup" class="uk-flex uk-flex-center uk-flex-middle" uk-height-viewport>
    //   <div class="custom-popup-content">
    //     <button class="uk-button compose-close" onClick={closePopup}>X</button>

    //     <h3>AI Compose {" "}
    //       <a
    //         href="https://intercom.help/ruebarueapp/en/articles/10375787-how-to-use-ai-compose"
    //         style={{ color: "black" }}
    //         target="_blank"
    //       >
    //         <img
    //           className="info-icon"
    //           src="/images/info-icon.svg"
    //           alt="info"
    //           uk-tooltip="AI Compose can help you craft professional responses, automate routine messages, and create engaging guestbook content with minimal effort. Click to view some samples."
    //         />
    //       </a>
    //     </h3>
    //     <div className="ai-compose">
    //       <textarea
    //           className="uk-textarea"
    //           value={guestQuestion}
    //           onChange={handleQuestionChange}
    //           rows="4"
    //       />
    //     </div>

    //     <div className="uk-margin-small-top">
    //       <button
    //         className="uk-button hms-btn hms-red-btn"
    //         onClick={composeMessage}
    //         type="button"
    //         disabled={isLoading || !guestQuestion.trim()}
    //       >
    //         {isLoading ? "Generating Message..." : "Generate Message"}
    //       </button>
    //     </div>

    //     {suggestedMessage && (
    //       <div className="uk-margin-top">
    //         <h4>Suggested Copy</h4>
    //         <div className="ai-assistant-response">
    //           {suggestedMessage}
    //         </div>
    //         <button
    //           type="button"
    //           className="uk-button hms-btn hms-red-btn uk-margin-small-top"
    //           onClick={copyMessage}
    //           disabled={isCopied}
    //         >
    //           {isCopied ? "Copied!" : "Copy to Clipboard"}
    //         </button>
    //       </div>
    //     )}

    //     {errorMessage && (
    //       <div className="uk-alert-danger uk-margin-top">{errorMessage}</div>
    //     )}
    //   </div>
    // </div>
     <>
      <MainBody id="hms-profile-settings-template" title="AI Assistant">
        <div className="uk-grid" uk-grid="">
          <div className="uk-width-1-2">
            <div className="uk-card uk-card-small uk-card-default hms-form-card">
              <div className="uk-flex">
                <div className="uk-width-expand">
                  <p>
                    AI Assistant can help you craft professional text responses, scheduled messages, emergency alerts, and create engaging guestbook content with minimal effort.
                  </p>
                  <div className="profile-form">
                    <label className="uk-form-label">
                      Enter Your Prompt to Generate an AI Response
                    </label>
                    <div className="ai-compose">
                      <textarea
                        className="uk-textarea"
                        value={guestQuestion}
                        onChange={handleQuestionChange}
                        rows="4"
                      />
                    </div>

                    <div className="uk-margin-small-top">
                      <button
                        className="uk-button hms-btn hms-red-btn"
                        onClick={composeMessage}
                        type="button"
                        disabled={isLoading || !guestQuestion.trim()}
                      >
                        {isLoading ? "Generating Message..." : "Generate Message"}
                      </button>
                    </div>

                    {suggestedMessage && (
                      <div className="uk-margin-top">
                        <label className="uk-form-label">Suggested Copy</label>
                        <div className="ai-assistant-response">
                          {suggestedMessage}
                        </div>
                        <button
                          type="button"
                          className="uk-button hms-btn hms-red-btn uk-margin-small-top"
                          onClick={copyMessage}
                          disabled={isCopied}
                        >
                          {isCopied ? "Copied!" : "Copy to Clipboard"}
                        </button>
                        <p className="hms-small-note uk-margin-small-top">
                          <img
                            className="guest-missing"
                            data-src="/images/warning.svg"
                            data-uk-img=""
                            loading="lazy"
                            src="/images/warning.svg"
                            alt="Warning"
                          />{" "}
                          This copy is generated by AI and may not always be accurate or up-to-date. Please use your discretion and verify critical information independently.
                        </p>
                      </div>
                    )}

                    {errorMessage && (
                      <div className="uk-alert-danger uk-margin-top">{errorMessage}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="uk-width-1-2">
            <h5>Sample Prompts</h5>
            <div className="uk-card ai-assistant-sample uk-margin-small-bottom">
              Compose a message to a guest requesting a refund due to noisy neighbors.
            </div>
            <div className="uk-card ai-assistant-sample uk-margin-small-bottom">
              Create a pre-arrival message with check-in instructions.
            </div>
            <div className="uk-card ai-assistant-sample uk-margin-small-bottom">
                Compose an urgent message about a maintenance issue (e.g., water leak).
            </div>
            <div className="uk-card ai-assistant-sample uk-margin-small-bottom">
              Write a tip for the guide to local restaurants.
            </div>
          </div>
        </div>
      </MainBody>
    </>
  );
}

export default MessageComposer;