import { AlertsProvider, AlertsContext } from "./alerts.provider";
import { AuthProvider, AuthContext } from "./auth.provider";
import { InboxProvider, InboxContext } from "./inbox.provider";
import { SchedulerProvider, SchedulerContext } from "./scheduler.provider";
import {
  SavedResponseProvider,
  SavedResponseContext,
} from "./responses.provider";
import { SettingsProvider, SettingsContext } from "./settings.provider";
import { TemplatesProvider, TemplatesContext } from "./templates.provider";
import { SurveysProvider, SurveysContext } from "./surveys.provider";
import { RentalsProvider, RentalsContext } from "./rentals.provider";
import { ReportsProvider, ReportsContext } from "./reports.provider";
import {
  ReservationsProvider,
  ReservationsContext,
} from "./reservations.provider";
import { ContactsProvider, ContactsContext } from "./contacts.provider";
import {
  MasterGuideProvider,
  MasterGuideContext,
} from "./master_guides.provider";
import {
  TransactionalProvider,
  TransactionalContext,
} from "./transactional.provider";
// import { MessageProvider, MessageContext } from "./messages.provider"
import {
  DestinationProvider,
  DestinationContext,
} from "./destination.provider";
import { UtilProvider, UtilContext } from "./util.provider";
import { StripeProvider, StripeContext } from "./stripe.provider";
import { WorkOrderProvider, WorkOrderContext } from "./workorders.provider";
import {
  AnnouncementsProvider,
  AnnouncementsContext,
} from "./announcements.provider";
import { GuidesProvider, GuidesContext } from "./guides.provider";
import { AccessoryContext, AccessoryProvider } from "./accessory.provider";
import { OrdersContext, OrdersProvider } from "./orders.provider";
import { ChatbotContext, ChatbotProvider } from "./chatbot.provider";
import { NotesContext, NotesProvider } from "./notes.provider";

let provider = (provider, props = {}) => [provider, props];

let providers = [
  provider(AlertsProvider),
  provider(AuthProvider),
  provider(InboxProvider),
  provider(SchedulerProvider),
  provider(SavedResponseProvider),
  provider(SettingsProvider),
  provider(TemplatesProvider),
  provider(SurveysProvider),
  provider(RentalsProvider),
  provider(ReportsProvider),
  provider(ReservationsProvider),
  provider(ContactsProvider),
  provider(MasterGuideProvider),
  provider(TransactionalProvider),
  // provider(MessageProvider),
  provider(DestinationProvider),
  provider(StripeProvider),
  provider(UtilProvider),
  provider(WorkOrderProvider),
  provider(AnnouncementsProvider),
  provider(GuidesProvider),
  provider(AccessoryProvider),

  // Add Ons
  provider(OrdersProvider),
  provider(ChatbotProvider),
  provider(NotesProvider),
];

const AppContexts = {
  contextAlerts: AlertsContext,
  AuthContext: AuthContext,
  InboxContext: InboxContext,
  SchedulerContext: SchedulerContext,
  SavedResponseContext: SavedResponseContext,
  SettingsContext: SettingsContext,
  TemplatesContext: TemplatesContext,
  SurveysContext: SurveysContext,
  RentalsContext: RentalsContext,
  ReportsContext,
  ReservationsContext: ReservationsContext,
  ContactsContext: ContactsContext,
  MasterGuideContext,
  TransactionalContext,
  // MessageContext,
  DestinationContext,
  StripeContext,
  UtilContext,
  WorkOrderContext,
  AnnouncementsContext: AnnouncementsContext,
  GuidesContext: GuidesContext,

  AccessoryContext: AccessoryContext,
  OrdersContext: OrdersContext,
  ChatbotContext,
  NotesContext: NotesContext,
};

const AppProviders = ({ children }) => {
  for (let i = providers.length - 1; i >= 0; --i) {
    const [Provider, props] = providers[i];
    children = <Provider {...props}>{children}</Provider>;
  }
  return children;
};

export { AppContexts, AppProviders };
