import { Image } from "uikit-react";
import classnames from "classnames";

const ImageRadio = ({
  category = "other",
  url = "images/other.svg",
  activeUrl = "images/other-active.svg",
  name = "radio",
  title = "",
  value,
  //backgroundColor = "#3b4b5b",
  onChange = () => {},
  ...props
}) => {
  const convertString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const categoryColors = {
    guest: "#00BFA5",
    owner: "#36A0F4",
    housekeeper: "#FF9800",
    maintenance: "#E64D49",
    lead: "#AA47BB",
    vendor: "#FFBF00",
    other: "#666666"
  };  

  const backgroundColor = categoryColors[category] || categoryColors.other;

  return (
    <div
      className="uk-border-pill"
      style={{
        backgroundColor: value ? backgroundColor : "white",
      }}
    >
      <label>
        <input
          className="uk-hidden"
          type="radio"
          name={name ?? null}
          checked={value}
          onChange={onChange}
          {...props}
        />
        <Image src={value ? activeUrl : url} />
        <span className={classnames("uk-inline", { "uk-text-muted": value })}>
        {convertString(title || category)}
        </span>
      </label>
    </div>
  );
};

export default ImageRadio;
