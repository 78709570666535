import { httpAI } from "../http.js";

const ChatbotService = {
  composeLog: (accountId, user_id, question) => httpAI.post("/compose", {
    accountId,
    user_id,
    question,
  }),
};

export default ChatbotService;
