// Import package components
import { Image, Link } from "uikit-react";
import classnames from "classnames";

// Import common components
import MacrosReservationKeys from "../MacroReservationKeys";
import Responses from "../Responses";

const SMSEmailInputbox = ({
  label = "Text*",
  onChange,
  value,
  required,
  readOnly,
  error,
  macrosOrResponse,
  onSavedReply,
  limit = true,
}) => {
  return (
    <>
      <div className="uk-form-controls">
        <div className="uk-width-1-1 uk-grid-margin uk-first-column">
          <label className="uk-form-label">Message*</label>
        </div>
        <textarea
          className={classnames("uk-textarea", { error })}
          rows="10"
          maxLength={limit ? 612 : -1}
          onChange={onChange}
          value={value}
          required={required}
          readOnly={readOnly}
        />
      </div>
      <div className="large-msg-typing-btn">
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <div className="uk-flex uk-flex-middle">
            {macrosOrResponse ? (
              <div>
                <span uk-tooltip="View Macros" className="macro-icon">
                  <img alt="macro-icon" src="/images/macro-icon.svg" />
                </span>
                <MacrosReservationKeys />
              </div>
            ) : (
              <div className="msg-editing-btn">
                <Responses onChange={onSavedReply} />
              </div>
            )}
            <a href="/ai-assistant"
              uk-tooltip="AI Assistant"
              target="_blank"
              rel="noopener noreferrer" 
            >
              <Image src="/images/ai-assistant.svg" />
            </a>
          </div>

          {limit && (
            <div>
              <div className="hms-small-note">
                Characters Remaining: {612 - value?.length}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SMSEmailInputbox;
