import { httpV2 } from "../http.js";

const NotesService = {
    create: (note) => httpV2.post('/notes', note),
    update: (note) => httpV2.put('/notes', note),
    delete: (id) => httpV2.delete(`notes/${id}`),
    getNotesByItem: (itemId, itemType) => httpV2.get(`/notes/${itemId}/${itemType}`),
    getAll: (criteria) => httpV2.get(`/notes?${new URLSearchParams(criteria).toString()}`) 
};

export default NotesService;
