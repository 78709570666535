// Import base components
import http, { httpV2 } from "../http.js";

const ReservationsService = {
  insertReservation: (objReservation) =>
    httpV2.post("/reservations", objReservation),
  updateReservation: (id, objReservation) =>
    httpV2.put(`/reservations/${id}`, objReservation),
  importReservations: (reservations) =>
    httpV2.post("/reservations/import", reservations),
  deleteReservation: (objReservation) =>
    httpV2.delete(`/reservations/${objReservation.guest_reservation_id}`),
  searchReservation: (criteria) =>
    httpV2.post("/reservations/search", criteria),
  getSelectedReservation: (reservation_id) =>
    httpV2.get(`reservations/${reservation_id}`),
  getReservationByPhone: (phone) => httpV2.get(`/reservations/phone/${phone}`),
  getGuestBook: (reservation_id) =>
    httpV2.get(`/reservations/guestbookview/${reservation_id}`),
  createSMS: (sms) => httpV2.post(`/messaging/outbound`, sms),
  sendScheduledMessage: (reservation_id, msg_id, contact = null) =>
    httpV2.post(
      `reservations/${reservation_id}/scheduled_message/${msg_id}/send`,
      { contact: contact },
    ),
  getSurvey: (reservation_id) =>
    httpV2.get(`/reservations/survey/${reservation_id}`),
  getSurveyResponse: (survey_id, survey_results_id) => 
    httpV2.get(`/reservations/survey/${survey_id}/${survey_results_id}`),
  getFivestar: (reservation_id) =>
    httpV2.get(`/reservations/fivestar/${reservation_id}`),
};

export default ReservationsService;
