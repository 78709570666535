import { useForm } from "react-hook-form";
import { Image } from "uikit-react";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import classnames from "classnames";

import { AppContexts } from "../../../providers";
import TableLoading from "../../common/Loading/table_loading";
import NoteDelete from "../delete";

const NoteAddContent = ({ itemId, itemType }) => {
    const { createNote, updateNote, deleteNote, loading, notes } = useContext(
        AppContexts.NotesContext,
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
        setError,
    } = useForm({
        // resolver: yupResolver(schema),
        defaultValues: {
            note_type: "notes",
        },
    });

    const [isShowAddForm, setIsShowAddForm] = useState(false);
    const [editReadyNote, setEditReadyNote] = useState();

    const handleAdd = () => {
        setIsShowAddForm(true);
        reset();
    };
    const handleCancel = () => {
        setIsShowAddForm(false);
        setEditReadyNote(undefined);
    };

    const handleEdit = (note) => {
        setEditReadyNote(note);
    };

    const validateNote = (note) => {
        let isValid = true;
        if (note.note_type === "notes") {
            if (!note.note) {
                setError("note", {
                    type: "custom",
                    message: "This field is requried.",
                });
                isValid = false;
            }
        } else {
            if (!note.event_date) {
                setError("event_date", {
                    type: "custom",
                    message: "This field is required.",
                });
                isValid = false;
            }
            if (!note.event_type) {
                setError("event_type", {
                    type: "custom",
                    message: "This field is required.",
                });
                isValid = false;
            }
        }
        return isValid;
    };

    const handleUpsert = async (note) => {
        if (!note.item_id) {
            note.item_id = itemId;
        }
        if (!note.item_type) {
            note.item_type = itemType;
        }
        let isValid = validateNote(note);

        if (isValid) {
            try {
                if (!editReadyNote) {
                    await createNote(note);
                } else {
                    await updateNote({ ...note, id: editReadyNote.id });
                }
                reset();
                setEditReadyNote(undefined);
                setIsShowAddForm(false);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleDelete = async () => {
        if (editReadyNote?.id) {
            try {
                await deleteNote(editReadyNote?.id);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        if (editReadyNote) {
            setIsShowAddForm(false);
            setValue("note", editReadyNote.note || "");
            setValue(
                "event_date",
                editReadyNote.event_date
                    ? moment.utc(editReadyNote.event_date).format("YYYY-MM-DD")
                    : "",
            );
            setValue("event_type", editReadyNote.event_type || "");
            setValue("note_type", editReadyNote.note_type || "notes");
        }
    }, [editReadyNote]);

    useEffect(() => {
        setEditReadyNote(undefined);
        setIsShowAddForm(false);
        reset();
    }, [itemId, itemType]);

    useEffect(() => {
        if (isShowAddForm) {
            setEditReadyNote(undefined);
        }
    }, [isShowAddForm]);

    const diffDate = (note) => {
        if (!note?.updated_at) {
            return "Unknown Date"; // Handle missing dates
        }

        const now = moment(); // Current local time
        const eventTime = moment.utc(note.updated_at).local(); // Convert UTC to local time
        const diff = moment.duration(now.diff(eventTime)); // Calculate difference

        // Normalize durations for accurate values
        const years = Math.floor(diff.asYears());
        const months = Math.floor(diff.asMonths() % 12);
        const days = Math.floor(diff.asDays() % 30);
        const hours = Math.floor(diff.asHours() % 24);
        const minutes = Math.floor(diff.asMinutes() % 60);

        if (years > 0) {
            return `${years} year(s) ago`;
        } else if (months > 0) {
            return `${months} month(s) ago`;
        } else if (days > 0) {
            return `${days} day(s) ago`;
        } else if (hours > 0) {
            return `${hours} hour(s) ago`;
        } else if (minutes > 0) {
            return `${minutes} minute(s) ago`;
        } else {
            return "Just now";
        }
    };

    const noteTypeWatch = watch("note_type");

    const formSection = () => (
        <form onSubmit={handleSubmit(handleUpsert)}>
            <div>
                <div className="uk-form-label">Type</div>
                <div className="uk-form-controls">
                    <label className="uk-margin-right">
                        <input
                            className="uk-radio uk-margin-small-right"
                            type="radio"
                            value="notes"
                            {...register("note_type")}
                        />
                        Text Note
                    </label>
                    <label>
                        <input
                            className="uk-radio uk-margin-small-right"
                            type="radio"
                            value="event"
                            {...register("note_type")}
                        />
                        Event Note
                    </label>
                </div>
            </div>
            {noteTypeWatch === "notes" ? (
                <div className="uk-margin-top">
                    <div className="uk-form-label">Note*</div>
                    <div className="uk-form-controls">
                        <textarea
                            className={classnames("uk-textarea", {
                                error: errors["note"],
                            })}
                            {...register("note")}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="uk-margin-top">
                        <div className="uk-form-label">Event Label*</div>
                        <div className="uk-form-controls">
                            <input
                                type="text"
                                className={classnames("uk-input", {
                                    error: errors["event_type"],
                                })}
                                {...register("event_type")}
                            />
                        </div>
                    </div>
                    <div className="uk-margin-top">
                        <div className="uk-form-label">Event Date*</div>
                        <div className="uk-form-controls">
                            <input
                                type="date"
                                className={classnames("uk-input", {
                                    error: errors["event_date"],
                                })}
                                {...register("event_date")}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="uk-margin-small-top">
                <button type="submit" className="uk-button hms-btn hms-red-btn">
                    SAVE
                </button>
                <button
                    className="uk-button hms-btn hms-btn-transparent"
                    onClick={handleCancel}
                >
                    CANCEL
                </button>
                {editReadyNote && (
                    <span uk-toggle="target: #note-delete">
                        <Image
                            src="/images/delete-icon.svg"
                            className="action-icon"
                        />
                    </span>
                )}
            </div>
        </form>
    );

    const noteRenderer = (note, index) => {
        return (
            <div className="uk-margin-bottom" key={`note-${note.id}`}>
                {editReadyNote && editReadyNote.id === note.id ? (
                    formSection()
                ) : (
                    <div className="note-section-content">
                        {note?.note_type === "notes"
                            ? note?.note
                            : `${note?.event_type} - ${
                                  note?.event_date
                                      ? new Date(
                                            note.event_date,
                                        ).toLocaleDateString("en-US", {
                                            month: "short",
                                            day: "2-digit",
                                            year: "numeric",
                                        })
                                      : "Unknown Date"
                              }`}
                    </div>
                )}
                <div className="uk-flex uk-flex-between uk-flex-center note-section-header">
                    <span>
                        {note.first_name} {note.last_name}
                    </span>
                    <span className="updated-at">{diffDate(note)}</span>
                    <span>
                        {note.id !== editReadyNote?.id && (
                            <span onClick={() => handleEdit(note)}>
                                <Image
                                    src="/images/edit-icon.svg"
                                    className="action-icon"
                                />
                            </span>
                        )}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                <TableLoading />
            ) : (
                <div className="uk-modal-body uk-margin-small-top">
                    <div>
                        <button
                            className="uk-button modal-btn"
                            onClick={handleAdd}
                        >
                            <Image
                                src="/images/modal-add.svg"
                                className="uk-margin-tiny-right"
                            />
                            Note
                        </button>
                    </div>
                    {isShowAddForm && formSection()}
                    <div>{notes?.map(noteRenderer)}</div>
                    <NoteDelete onDelete={handleDelete} />
                </div>
            )}
        </>
    );
};

export default NoteAddContent;
