// Import package components
import { useContext } from "react";
import UIkit from "uikit";

import Dialog from "../common/Dialog";
import { AppContexts } from "../../providers";

const NoteDelete = ({ onDelete }) => {
    const notesContext = useContext(AppContexts.NotesContext);
    const handleDelete = async () => {
        await onDelete();
    };

    return (
        <>
            <Dialog id="note-delete">
                <div className="uk-modal-dialog uk-modal-body">
                <button
                    className="uk-modal-close-default"
                    type="button"
                    uk-close="true"
                ></button>
                <h2 className="uk-modal-title">
                    Delete a Note
                </h2>

                <p>
                    Are you sure you want to delete this note?
                </p>
                <p className="uk-text-right">
                    <button
                        className="uk-button hms-btn uk-modal-close uk-modal-delete-cancel"
                        type="button"
                    >
                    Cancel
                    </button>
                    <button
                        className="uk-button hms-btn hms-gray-btn"
                        type="button"
                        onClick={handleDelete}
                    >
                    {notesContext?.loading == true ? (
                        <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                        "Delete"
                    )}
                    </button>
                </p>
                </div>
            </Dialog>
        </>
    );
};

export default NoteDelete;
