import moment from "moment";
import React, { useContext, useState, useEffect, useMemo } from "react";

import { AppContexts } from "../../providers";
import { Image, Link } from "uikit-react";

// Import common components
import ReservationNumber from "../common/ReservationNumber";
// import NoteAddContent from "../notes/note-add/note-add-content";

const GuestDetails = (props) => {
  let { contact = null, guest_reservations = [], guest=null } = props;
  let threadInfo = (!guest || guest?.guest_id == "0") ? (contact || {}) : (guest || {});
  // const { notes } = useContext(AppContexts.NotesContext);
  const { user } = useContext(AppContexts.AuthContext);

  let [indexes, setIndexes] = useState({
    current: 0,
    upcoming: 0,
    past: 0,
  });

  const sortedReservations = useMemo(() => {
    const current = [];
    const upcoming = [];
    const past = [];
    if (guest_reservations.length) {
      guest_reservations.forEach((it) => {
        let check_in = it.check_in;
        let check_out = it.check_out;
        if (check_in > moment().format('YYYY-MM-DD')) {
          upcoming.push(it);
        } else if (check_out < moment().format('YYYY-MM-DD')) {
          past.push(it);
        } else {
          current.push(it);
        }
      });
    }
    return {current, upcoming, past};
  }, [guest_reservations]);

  const incrementIndex = (tab) => {
    indexes[tab] < sortedReservations[tab].length - 1 
      ? setIndexes({ ...indexes, [tab]: indexes[tab] + 1 })
      : setIndexes({ ...indexes, [tab]: 0});
  };

  const decrementIndex = (tab) => {
    indexes[tab] > 0 
      ? setIndexes({ ...indexes, [tab]: indexes[tab] - 1 })
      : setIndexes({ ...indexes, [tab]: sortedReservations.length - 1});
  };

  const reservationRenderer = (tab) => {
    const reservation = sortedReservations[tab][indexes[tab]];
    if (sortedReservations[tab].length === 0) {
      return null;
    }
    return (
      <div className="guest-chat-info-wrapper">
        {sortedReservations[tab].length > 1 &&
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>Booking {indexes[tab] + 1} of {sortedReservations[tab].length}</div>
            <div style={{ display: "flex", gap: "8px" }}>
              <span uk-icon="arrow-left" className="uk-icon-button uk-icon" onClick={() => decrementIndex(tab)}>
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <polyline fill="none" stroke="#000" points="10 14 5 9.5 10 5"></polyline>
                  <line fill="none" stroke="#000" x1="16" y1="9.5" x2="5" y2="9.52"></line>
                </svg>
              </span>
              <span uk-icon="arrow-right" className="uk-icon-button uk-icon" onClick={() => incrementIndex(tab)}>
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <polyline fill="none" stroke="#000" points="10 5 15 9.5 10 14"></polyline>
                  <line fill="none" stroke="#000" x1="4" y1="9.5" x2="15" y2="9.5"></line>
                </svg>
              </span>
            </div>
          </div>
        }
        <div className="uk-margin-small-top message-text-group">
          <span uk-tooltip="Check-In - Check-Out">
            <Image src="/images/check-in-check-out.svg" />
          </span>
          <div className="chat-value">
            {moment(reservation?.check_in).format("MMM DD, YYYY")} -{" "}
            {moment(reservation?.check_out).format("MMM DD, YYYY")}
          </div>
        </div>
        <div className="uk-margin-small message-text-group">
          <span uk-tooltip="Property ID">
            <Image src="/images/property-id.svg" />
          </span>
          <div className="chat-value">
            {reservation?.pms_id}
          </div>
        </div>
        <div className="uk-margin-small message-text-group">
          <span uk-tooltip="Address">
            <Image src="/images/address.svg" />
          </span>
          <div className="chat-value">
            {reservation?.pms_address}
          </div>
        </div>
        <div className="uk-margin-small message-text-group">
          <span uk-tooltip="Reservation Number">
            <Image src="/images/reservation-number.svg" />
          </span>
          <div className="chat-value">
            <ReservationNumber
              hostID={reservation?.host_id}
              pms={user?.details?.pms}
              resevationID={reservation?.reservation_id}
              nativeID={reservation?.native_id}
            />
          </div>
        </div>
        {
          reservation?.door_code && (
          <div className="uk-margin-small message-text-group">
            <span uk-tooltip="Door Code">
              <Image src="/images/door-code.svg" />
            </span>
            <div className="chat-value">
              {reservation?.door_code}
            </div>
          </div>
        )}
        {reservation?.source && (
          <div className="uk-margin-small message-text-group">
            <span uk-tooltip="Booking Source">
              <Image src="/images/booking-source.svg" />
            </span>
            <div className="chat-value">
              {reservation?.source}
            </div>
          </div>
        )}
      </div>
    )
  };

  return (
      <div className="uk-card uk-card-default uk-card-small uk-card-body hms-msg-cards hms-booking-info">
        <div className="message-text-group guest-info" uk-grid>
          <span
            className="message-text-group-icon"
            uk-tooltip={`title: ${
              threadInfo.category
                ?.toLowerCase()
                .replace(/\b\w/g, (s) => s.toUpperCase()) || "Guest"
            }; pos: bottom-left; cls: uk-active contact-tooltip`}
          >
            <Image src={`/images/${threadInfo.category || 'guest'}-active.svg`} />
          </span>
          <div className="uk-width-expand">
            <div className="guest-chat-info">
              <div className="chat-guest-value">{threadInfo.first_name} {threadInfo.last_name}</div>
              <div className="chat-guest-value">{threadInfo.formatted_phone}</div>
              <div className="chat-guest-value">{threadInfo.email}</div>
              {!threadInfo.category && <div className="chat-guest-value">{guest_reservations.length} booking(s)</div>}
            </div>
          </div>
        </div>

        {!threadInfo.category && <div>
          {/* 
          <ul
            data-uk-tab
            uk-tab="connect: #message-aside; animation: uk-animation-fade"
            className="uk-margin-small-bottom uk-margin-small-top"
          >
            <li className="uk-active">
              <Link href="#">Bookings ({guest_reservations.length})</Link>
            </li>
            <li>
              <Link href="#">Notes ({notes?.length})</Link>
            </li>
          </ul>
          */}
          <div>
            {/* <ul id="message-aside" className="uk-switcher">*/}
            <ul id="message-aside" className="uk-margin-top">
              <div id="guest-details">
                <ul uk-accordion="multiple: true" animation="false" className="uk-accordion bookings">
                  {sortedReservations.current.length !== 0 && <li className="uk-open">
                    <a className="uk-accordion-title" href="#">Current ({sortedReservations.current.length})</a>
                    <div className="uk-accordion-content">
                      {reservationRenderer('current')}
                    </div>
                  </li>}
                  {sortedReservations.upcoming.length !== 0 && <li>
                    <a class="uk-accordion-title" href="#">Upcoming ({sortedReservations.upcoming.length})</a>
                    <div className="uk-accordion-content">
                        {reservationRenderer('upcoming')}
                    </div>
                  </li>}
                  {sortedReservations.past.length !== 0 && <li>
                    <a class="uk-accordion-title" href="#">Past ({sortedReservations.past.length})</a>
                    <div className="uk-accordion-content">
                      {reservationRenderer('past')}
                    </div>
                  </li>}
                </ul>
              </div>
              {/* <div id="note-details">
                {
                  Number(contact?.id) || Number(guest?.guest_id) ? 
                  (
                    <NoteAddContent
                      itemId={Number(contact.id) || Number(guest?.guest_id)}
                      itemType={Number(contact.id) ? 'contact' : Number(guest.guest_id) ? 'guest' : ''}
                    />
                  ) : null
                }
              </div> */}
            </ul>
          </div>
        </div>}
      </div>
  );
};

export default GuestDetails;
