import React, {
  useMemo,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import GoogleAutoComplete from "../common/GoogleAutoComplete";
import { AppContexts } from "../../providers";
import UIkit from "uikit";

const schema = yup.object().shape({
  pms_id: yup.string().required(),
});

const AddPropertyGuide = () => {
  const [placeId, setPlaceId] = useState(null);
  const DestinationContext = useContext(AppContexts.DestinationContext);
  const UtilContext = useContext(AppContexts.UtilContext);
  const { user } = useContext(AppContexts.AuthContext);
  const RentalsContext = useContext(AppContexts.RentalsContext);
  const [isValidate, setValidate] = useState(true);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const history = useHistory();
  const offcanvasRef = useRef(null);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const listDestinations = useMemo(() => {
    if (!DestinationContext.listDestinations) {
      return [];
    } else {
      return DestinationContext.listDestinations;
    }
  }, [DestinationContext.listDestinations]);

  const handleRegister = async (data) => {
    try {
      let rental = {};
      if (RentalsContext.isNew) {
        placeId ? setValidate(true) : setValidate(false);
        RentalsContext.setLoading(true);
        let place = await UtilContext.place(placeId);
        rental = {
          destination_id: data.destination_id == "" ? 0 : data.destination_id,
          google_place_id: place.place_id,
          host_id: user.account_id,
          pms_id: data.pms_id || "",
          name: data.name || place.name,
          address: place.formatted_address,
          lat: data.lat || place.geometry.location.lat,
          lng: data.lng || place.geometry.location.lng,
          property_code: "",
        };
        rental = await RentalsContext.createRental(rental);
      } else {
        rental = {
          destination_id: data.destination_id == "" ? 0 : data.destination_id,
          host_id: user.account_id,
          pms_id: data.pms_id || "",
          name: data.name,
          property_code: "",
        }
        if (placeId !== RentalsContext.rental.google_place_id) {
          RentalsContext.setLoading(true);
          let place = await UtilContext.place(placeId);
          rental = {
            destination_id: data.destination_id == "" ? 0 : data.destination_id,
            google_place_id: place.place_id,
            host_id: user.account_id,
            pms_id: data.pms_id || "",
            name: data.name || place.name,
            address: place.formatted_address,
            lat: data.lat || place.geometry.location.lat,
            lng: data.lng || place.geometry.location.lng,
            property_code: "",
          };
        }
        rental = await RentalsContext.updateRental(
          RentalsContext.rental.rental_id,
          {
            ...RentalsContext.rental,
            ...rental,
          }
        );
      }

      if (!rental) {
        UIkit.notification("Failed", "error");
      } else {
        UIkit.modal("#addPropertyGuide-modal").hide();
        UIkit.notification(
          RentalsContext.isNew ? "Added Successfully" : "Updated Successfully",
          "success"
        );
        RentalsContext.setLoading(false);

        RentalsContext.get();
        if (RentalsContext.isNew)
          history.push(`properties/${rental.rental_id}`);
        RentalsContext.setIsNew(false);
      }
    } catch (err) {}
  };

  const handleBeforeHide = (e) => {
    if (e.target === offcanvasRef.current) {
      setAutoCompleteValue("");
      reset();
      clearErrors();
      RentalsContext.setRental({});
    }
  };

  useEffect(() => {
    const offcanvasElement = UIkit.offcanvas(offcanvasRef.current);
    const offcanvasDOMElement = offcanvasElement.$el;
    offcanvasDOMElement.addEventListener("beforehide", handleBeforeHide);
    return () => {
      offcanvasDOMElement.removeEventListener("beforehide", handleBeforeHide);
    };
  }, []);

  useEffect(() => {
    if (RentalsContext.isNew) {
      reset();
      setAutoCompleteValue("");
    } else {
      setValue("pms_id", RentalsContext.rental.pms_id);
      setValue("name", RentalsContext.rental.name);
      setValue("destination_id", RentalsContext.rental.destination_id);
      setPlaceId(RentalsContext.rental.google_place_id);
      setAutoCompleteValue(RentalsContext.rental.address);
      setValue('lat', RentalsContext.rental.lat);
      setValue('lng', RentalsContext.rental.lng);
    }
  }, [RentalsContext.isNew, RentalsContext.rental]);

  useEffect(() => {
    if (RentalsContext.error)
      setError("pms_id", { type: "custom", message: RentalsContext.error });
  }, [RentalsContext.error]);

  useEffect(() => {
    RentalsContext.setPMSError(null);
  }, [watch("pms_id")]);

  const destination_id = useMemo(
    () => getValues("destination_id"),
    [watch("destination_id")]
  );

  const handleError = (err) => {
    console.log(err);
  };

  return (
    <div
      id="addPropertyGuide-modal"
      className="uk-modal-full hms-modals uk-modal"
      uk-offcanvas="overlay: true; flip: true;"
      ref={offcanvasRef}
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">
              {RentalsContext.isNew ? "Add" : "Edit"} a Home Guide
            </h2>
          </div>
          <div className="uk-modal-body">
            <form
              className="uk-grid-small"
              uk-grid="true"
              onSubmit={handleSubmit(handleRegister, handleError)}
            >
              <p className="secondary">
                Subscription is based on the number of home guides. Adding a
                home guide will increase your subscription amount.
              </p>
              <div className="uk-width-1-1">
                <div className="uk-form-controls">
                  <label className="uk-form-label">Property Address*</label>
                  <GoogleAutoComplete
                    className={classnames({ error: !isValidate })}
                    width="100%"
                    onSelect={(_, value) => setPlaceId(value.text)}
                    onChange={(e) => setAutoCompleteValue(e)}
                    address={autoCompleteValue}
                  />
                </div>
                <div className="hms-small-itelic">
                  Begin typing and select a suggested address.
                </div>
              </div>
              <div className="uk-width-1-1">
                <div className="uk-form-controls">
                  <label className="uk-form-label">
                    Property Id{" "}
                    <img
                      className="info-icon"
                      src="/images/info-icon.svg"
                      uk-tooltip="Use the ID assigned in you property management software."
                    />
                  </label>
                  <input
                    className={classnames("uk-input", {
                      error: errors["pms_id"],
                    })}
                    type="text"
                    {...register("pms_id")}
                  />
                  <label className="uk-label-danger">
                    {RentalsContext.error}
                  </label>
                </div>
              </div>
              <div className="uk-width-1-1">
                <div className="uk-form-controls">
                  <label className="uk-form-label">
                    Guide Name{" "}
                    <img
                      className="info-icon"
                      src="/images/info-icon.svg"
                      uk-tooltip="Title of the home guide, visibile to guests."
                    />
                  </label>
                  <input
                    className={classnames("uk-input", {
                      error: errors["name"],
                    })}
                    type="text"
                    {...register("name")}
                  />
                </div>
              </div>
              <div className="uk-width-1-1">
                <div uk-form-custom="target: > * > span:first-child">
                  <label className="uk-form-label">Area Guide</label>
                  <select {...register("destination_id")}>
                    <option value="">
                      Select a area guide for this property
                    </option>
                    {listDestinations.map((destination_guide, index) => (
                      <option
                        value={destination_guide.destination_id}
                        key={index}
                      >
                        {destination_guide.name}
                      </option>
                    ))}
                  </select>
                  <button
                    className="uk-button uk-button-default"
                    type="button"
                    tabIndex="-1"
                  >
                    <span>
                      {!!listDestinations.find(
                        (item) => item.destination_id === destination_id
                      )
                        ? listDestinations.find(
                            (item) => item.destination_id === destination_id
                          ).name
                        : "Select a area guide for this property"}
                    </span>
                    <span
                      uk-icon="icon: chevron-down"
                      className="hms-align-right"
                    ></span>
                  </button>
                </div>
              </div>
              {!RentalsContext.isNew && <div className="uk-width-1-2@s">
                <div className="uk-form-controls">
                  <label className="uk-form-label">
                    Latitude
                  </label>
                  <input
                    className={classnames("uk-input", {
                      error: errors["name"],
                    })}
                    type="text"
                    {...register("lat")}
                  />
                </div>
              </div>}
              {!RentalsContext.isNew && <div className="uk-width-1-2@s">
                <div className="uk-form-controls">
                  <label className="uk-form-label">
                    Longitude
                  </label>
                  <input
                    className={classnames("uk-input", {
                      error: errors["name"],
                    })}
                    type="text"
                    {...register("lng")}
                  />
                </div>
              </div>}
              <div>
                <button
                  className="uk-button hms-btn hms-red-btn"
                  type="submit"
                  disabled={RentalsContext.loading}
                >
                  {RentalsContext.loading ? (
                    <div data-uk-spinner="ratio: 0.5;">Saving </div>
                  ) : (
                    "Continue"
                  )}
                </button>
                <button
                  className="uk-button hms-btn uk-offcanvas-close"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPropertyGuide;
