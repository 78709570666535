import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { Select, SelectOption, Image, Link, Flex } from "uikit-react";
import UIkit from "uikit";
import moment from "moment";
import classnames from "classnames";

import { AppContexts } from "../../providers/";
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import { useCookies } from "react-cookie";

const searchValue = {
  search_term: "",
  filters: "all",
  preset: "soon",
  start_date: "2000-01-01",
  end_date: "2050-12-31",
  end_type: "check_in",
  checked_in: false,
  checked_out: false,
  categories: "",
  type: "message",
};

export default () => {
  const inboxContext = useContext(AppContexts.InboxContext);
  const { listReservations, searchReservation } = useContext(
    AppContexts.ReservationsContext,
  );

  const { notification } = useContext(AppContexts.UtilContext);
  const { setNotes } = useContext(AppContexts.NotesContext);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const [option, setOption] = useState("");
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const {
    inbox,
    selectedThreadId,
    selectThread,
    getThreadDetail,
    threadCnt,
    setThreadMeta,
    setReservation,
    setSelectedThreadId,
    setThreadDetail,
  } = inboxContext;
  const history = useHistory();

  useEffect(() => {
    inboxContext.refresh(pageNo, option, recordsPerPage);
  }, [option, recordsPerPage, pageNo]);

  useEffect(() => {
    setIsFirst(false);
  }, []);

  const handleSelect = (threadId) => {
    let current = +window.location.pathname.split("/")[2];
    if (threadId === null) {
      history.push("/messages/new");
      setSelectedThreadId("new");
      setThreadDetail({});
    } else if (+current !== +threadId) {
      history.push(`/messages/${threadId}`);
      selectThread(threadId);
    }
    setNotes([]);
  };

  const handleSelectOption = (e) => {
    setOption(e.target.value);
  };

  const reservations = useMemo(() => {
    let sorts = listReservations?.sort((prev, next) => {
      return next.check_in?.localeCompare(prev.check_in);
    });
    let buf = [];
    sorts.forEach((sort) => {
      if (sort.thread_id === null) {
        buf.push(sort);
      } else {
        if (!buf.find((item) => item?.thread_id === sort.thread_id)) {
          buf.push(sort);
        }
      }
    });
    return buf;
  }, [listReservations]);

  const handleGuest = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search !== "") {
      searchValue.search_term = search.trim();
      searchReservation(searchValue);
    }
  }, [search]);

  useEffect(() => {
    if (!!notification) {
      inboxContext.refresh(pageNo, option, recordsPerPage);
      if (+notification.thread_id === +window.location.pathname.split("/")[2]) {
        getThreadDetail(notification.thread_id);
      }
    }
  }, [notification]);

  const handleMeta = async (thread_id, metaType, flag) => {
    let result = await inboxContext.setMeta(thread_id, {
      thread_id,
      token: metaType,
    });
    if (result) {
      let message;
      if (metaType === "unread") {
        message = result.thread.meta?.find((item) => item.token === "unread")
          ? "Message Marked as Unread"
          : "Message Marked as Read";
      } else if (metaType === "priority") {
        message = result.thread.meta?.find((item) => item.token === "priority")
          ? "Message Prioritized"
          : "Message Deprioritized";
      } else {
        message = result.thread.meta?.find((item) => item.token === "archived")
          ? "Message Archived"
          : "Message Unarchived";
      }
      UIkit.notification(message, "success");
      flag ? inboxContext.refresh(0, option, recordsPerPage) : searchReservation(searchValue);
    }
  };

  const reservationRenderer = (reservation, index) => {
    return (
      <div
        className={classnames("chat-guest-contact-wrapper", {
          "active-chat-guest-contact":
            selectedThreadId == reservation.thread_id,
          "thread-unread": reservation.thread_meta?.indexOf("unread") >= 0,
        })}
        key={index}
        onClick={() => {
          handleSelect(reservation.thread_id);
          setReservation(reservation);
        }}
      >
        <div className="message-text-group">
          <Image src="/images/other-active.svg" />
          <div>
            <div className="chat-guest-contact-name">
              {reservation.first_name} {reservation.last_name}
            </div>
            <div className="chat-guest-contact-address">
              {reservation.pms_id}
            </div>
            <div className="chat-guest-contact-date">
              {reservation.check_in
                ? moment
                    .utc(reservation.check_in)
                    .format("MMM DD, YYYY hh:mm A")
                : "Invalid date"}
            </div>
          </div>
        </div>
        <div className="messages-btn-group">
          <button
            className={classnames("uk-button hms-simple-icon-btn", {
              "check-icon-gray":
                reservation.thread_meta?.indexOf("unread") == -1,
              "check-icon-unread":
                reservation.thread_meta?.indexOf("unread") >= 0,
            })}
            type="button"
            uk-tooltip={
              reservation.thread_meta?.indexOf("unread") >= 0
                ? "Mark as Read"
                : "Mark as Unread"
            }
            onClick={() => {
              handleMeta(reservation.thread_id, "unread", true);
            }}
          ></button>
          <button
            className={classnames("uk-button hms-simple-icon-btn", {
              "flag-icon-red":
                reservation.thread_meta?.indexOf("priority") >= 0,
              "flag-icon-gray":
                reservation.thread_meta?.indexOf("priority") == -1,
            })}
            type="button"
            uk-tooltip={
              reservation.thread_meta?.indexOf("priority") >= 0
                ? "Unprioritize"
                : "Prioritize"
            }
            onClick={() => handleMeta(reservation.thread_id, "priority", false)}
          ></button>
          <button
            className={classnames("uk-button hms-simple-icon-btn", {
              "basket-icon-purple":
                reservation.thread_meta?.indexOf("archived") >= 0,
              "basket-icon-gray":
                reservation.thread_meta?.indexOf("archived") == -1,
            })}
            type="button"
            uk-tooltip={
              reservation.thread_meta?.indexOf("archived") >= 0
                ? "Unarchive"
                : "Archive"
            }
            onClick={() => handleMeta(reservation.thread_id, "archived", false)}
          ></button>
        </div>
      </div>
    );
  };

  const threadRenderer = (thread, index) => {
    var {
      contact_first_name,
      contact_last_name,
      guest_first_name,
      guest_last_name,
      formatted_contact_phone,
      formatted_guest_phone,
      last_msg,
    } = thread;

    var firstName =
      contact_first_name ||
      guest_first_name ||
      formatted_contact_phone ||
      formatted_guest_phone ||
      "[Unknown]";

    var lastName = contact_last_name || guest_last_name || "";

    return (
      <div
        className={classnames("chat-guest-contact-wrapper", {
          "active-chat-guest-contact": selectedThreadId == thread.id,
          "thread-unread": thread.meta_values.indexOf("unread") >= 0,
        })}
        key={index}
      >
        <div
          className="message-text-group"
          onClick={() => {
            handleSelect(thread.id);
          }}
        >
          <span
            className="message-text-group-icon"
            uk-tooltip={`title: ${
              thread.contact_category
                ?.toLowerCase()
                .replace(/\b\w/g, (s) => s.toUpperCase()) || "Guest"
            }; pos: bottom-left; cls: uk-active contact-tooltip`}
          >
            {thread.contact_category !== null ? (
              <Image src={`/images/${thread.contact_category}-active.svg`} />
            ) : (
              <Image src="/images/guest-active.svg" />
            )}
          </span>
          <div>
            <div className="semi-bold-font">
              {[firstName, lastName].join(" ")}
            </div>
            {
              thread.contact_category === null && <div
                className="chat-guest-contact-address uk-margin-tiny-top uk-margin-tiny-bottom"
                uk-tooltip={thread.unit_code}
              >
                {thread.unit_code?.length > 30
                  ? `${thread.unit_code.slice(0, 30)}...`
                  : thread.unit_code}
              </div>
            }
            <div
              className={
                thread.meta_values.indexOf("unread") >= 0
                  ? "chat-guest-unread-message"
                  : "chat-guest-message"
              }
            >
              {last_msg?.length > 60 ? `${last_msg.slice(0, 60)}...` : last_msg}
            </div>
            <div className="chat-guest-contact-date">
              {moment
                .utc(thread.last_message_on)
                .local()
                .format("MMM DD, YYYY hh:mm A")}
            </div>
          </div>
        </div>
        <div className="messages-btn-group">
          <button
            className={classnames("uk-button hms-simple-icon-btn", {
              "check-icon-gray": thread.meta_values.indexOf("unread") == -1,
              "check-icon-unread": thread.meta_values.indexOf("unread") >= 0,
            })}
            uk-tooltip={
              thread.meta_values?.indexOf("unread") >= 0
                ? "Mark as Read"
                : "Mark as Unread"
            }
            onClick={() => handleMeta(thread.id, "unread", true)}
          ></button>
          <button
            className={classnames("uk-button hms-simple-icon-btn", {
              "flag-icon-red": thread.meta_values.indexOf("priority") >= 0,
              "flag-icon-gray": thread.meta_values.indexOf("priority") == -1,
            })}
            onClick={() => handleMeta(thread.id, "priority", true)}
            uk-tooltip={
              thread.meta_values.indexOf("priority") >= 0
                ? "Unprioritize"
                : "Prioritize"
            }
          ></button>
          <button
            className={classnames("uk-button hms-simple-icon-btn", {
              "basket-icon-purple": thread.meta_values.indexOf("archived") >= 0,
              "basket-icon-gray": thread.meta_values.indexOf("archived") == -1,
            })}
            onClick={() => handleMeta(thread.id, "archived", true)}
            uk-tooltip={
              thread.meta_values.indexOf("archived") >= 0
                ? "Unarchive"
                : "Archive"
            }
          ></button>
        </div>
      </div>
    );
  };

  const handleIndexChanged = (newPageIndex) => {
    if (!isFirst) {
      setSelectedThreadId(-1);
      setThreadDetail({});
      history.push("/messages");
    }
    setPageNo(newPageIndex);
    inboxContext.refresh(newPageIndex, option, recordsPerPage);
  };

  return (
    <aside className="uk-card uk-card-default uk-card-small uk-card-body uk-width-1-4@m hms-msg-cards chat-guest-contact-card">
      <div className="chat-msg-type">
        <div>
          <Select onChange={handleSelectOption}>
            <SelectOption value="">Inbox</SelectOption>
            <SelectOption value="priority">Priority</SelectOption>
            <SelectOption value="unread">Unread</SelectOption>
            <SelectOption value="archived">Archived</SelectOption>
            <SelectOption value="all">All Messages</SelectOption>
          </Select>
        </div>
        <div className="uk-search uk-search-default">
          {!search ? (
            <span className="uk-search-icon-flip" uk-search-icon="true"></span>
          ) : (
            <span
              className="uk-close-icon uk-close-icon-flip"
              uk-icon="close"
              onClick={() => {
                handleGuest({ target: { value: "" } });
              }}
            ></span>
          )}
          <input
            className="uk-search-input"
            type="search"
            placeholder="Guest Name Or Property ID"
            value={search}
            onChange={handleGuest}
          />
        </div>
      </div>
      <div>
        <div
          className="chat-guest-contact"
          uk-viewport-box="footer-el: .inbox-pagination .hms-pagination"
        >
          {search == "" ? (
            inbox?.length === 0 ? (
              <div className="uk-margin-top">
                There are no messages in this folder
              </div>
            ) : (
              inbox?.map((thread, index) => threadRenderer(thread, index))
            )
          ) : reservations?.length === 0 ? (
            <div className="uk-margin-top">
              There are no messages in this folder
            </div>
          ) : (
            reservations?.map((reservation, index) =>
              reservationRenderer(reservation, index),
            )
          )}
        </div>
        <div className="inbox-pagination">
          <div className="inbox-pagination-part">
            <Pagination
              count={threadCnt}
              index={pageNo}
              perPage={recordsPerPage}
              onIndexChanged={handleIndexChanged}
            />
          </div>
          <RecordsPerPage />
        </div>
      </div>
    </aside>
  );
};
